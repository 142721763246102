<script>
export default {
  name: 'AffiliationIndex',
  head: {
    title: 'Affiliation',
  },
  computed: {
    siteURL() {
      return `${this.$env.rootURL}?parrain=${this.$store.getters['auth/user'].god_father_code}`;
    },
    appURL() {
      const parrain = this.$store.getters['auth/user'].god_father_code;
      const { href } = this.$router.resolve({ name: 'register', query: { parrain } });
      return `${this.$env.appURL}${href}`;
    },
    authUser() {
      return this.$store.getters['auth/user'];
    },
  },
};
</script>

<template>
  <div>
    <h2 class="title is-4 is-size-5-mobile has-text-centered">
      Qu'est-ce que l'affiliation ?
    </h2>
    <div class="box content is-medium w-640 mx-auto is-size-7-mobile">
      <p>
        L'affiliation, c'est tout simplement être un <strong>ambassadeur</strong> de Teachizy,
        un parrain, un messager, un promoteur !
      </p>
      <p>
        <strong>Votre rôle, c'est de parler de Teachizy aux bonnes personnes.</strong>
        <br> Ceux à qui l'outil va vraiment servir et être utile.
      </p>
      <p>
        Pour chaque inscrit venant de votre part, nous vous reversons
        <strong>{{ authUser.god_father_commission }}% des revenus générés</strong> par cette personne
        grâce à notre outil !
      </p>
    </div>
    <div class="box content is-medium w-640 mx-auto is-size-7-mobile">
      <h3 class="title is-5 has-text-centered">
        Exemple concret
      </h3>
      <ul>
        <li class="mb-5">
          Une personne s'inscrit au Pack PRO à
          <strong>{{ $constants.APP_MONTHLY_PRICE }}&euro;/mois</strong>
          <p class="is-size-7">
            → Nous vous reversons
            <strong>
              {{ Math.round(($constants.APP_MONTHLY_PRICE * (authUser.god_father_commission / 100)) * 100) / 100 }}&euro;/mois
            </strong>
            (tant qu'elle est abonnée à ce pack)
          </p>
        </li>
        <li>
          Une personne ayant le Pack DÉCOUVERTE fait <strong>1 000&euro;</strong> de ventes
          <p class="is-size-7">
            → Teachizy prend une commission de {{ $constants.APP_COMMISSION }}%, soit
            <strong>{{ Math.round(($constants.APP_COMMISSION / 100) * 1000) }}&euro;</strong>
            <br>
            → Nous vous reversons {{ authUser.god_father_commission }}% de la commission, soit
            <strong>
              {{ Math.round((($constants.APP_COMMISSION / 100) * 1000) * (authUser.god_father_commission / 100)) }}&euro;
            </strong>
          </p>
        </li>
      </ul>

      <p class="is-size-8 has-text-centered">
        Tous les prix sont exprimés en TTC, TVA appliquée le cas échéant.
      </p>
    </div>

    <h2 class="title is-4 has-text-centered mt-20">
      Je veux être affilié ! Comment faire ?
    </h2>
    <div class="box content is-medium w-640 mx-auto is-size-7-mobile">
      <p>
        Il vous suffit de promouvoir notre outil avec votre lien de parrainage.
      </p>
      <p class="has-text-centered">
        Le lien vers l'accueil :<br>
        <a class="valign-middle" :href="siteURL">
          {{ $env.rootURL }}?parrain={{ $store.getters['auth/user'].god_father_code }}
        </a>
        <b-tooltip label="Copier">
          <b-button
            class="valign-middle"
            icon-left="copy"
            v-clipboard:copy="siteURL"
            v-clipboard:success="() => this.$copySuccess()"
            v-clipboard:error="() => this.$copyError()"
          />
        </b-tooltip>
      </p>
      <p class="has-text-centered">
        Le lien direct vers l'inscription (conseillé) :<br>
        <a class="valign-middle" :href="appURL">
          {{ appURL }}
        </a>
        <b-tooltip label="Copier">
          <b-button
            class="valign-middle"
            icon-left="copy"
            v-clipboard:copy="appURL"
            v-clipboard:success="() => this.$copySuccess()"
            v-clipboard:error="() => this.$copyError()"
          />
        </b-tooltip>
      </p>
    </div>

    <h2 class="title is-4 has-text-centered mt-20">
      Conditions générales
    </h2>
    <div class="box content is-medium w-640 mx-auto is-size-7-mobile">
      <p>
        Nous vous invitons à lire en détail nos
        <BaseShowCmsPage class="tdecoration-underline" page="cgv">
          conditions générales.
        </BaseShowCmsPage>
        <br>En résumé,
        pour être un affilié Teachizy, vous devez :
      </p>
      <ol>
        <li>
          Être en mesure de nous facturer (donc avoir un SIRET)
          <br>
          <span class="is-size-7">
            → Il est illégal en France de rémunérer des particuliers en argent.
          </span>
        </li>
        <li>
          Nous envoyer la facture avant le 25 du mois
          <br>
          <span class="is-size-7">
            → Vous serez payé(e) dans les 5 jours qui suivent le 25 dudit mois
          </span>
          <br>
          <span class="is-size-7">
            → Vous serez payé(e) pour tous vos revenus générés le mois précédent
          </span>
          <br>
          <span class="is-size-7">
            → Exemple : vous nous envoyez votre facture le 15 mars,
            vous serez payé(e) entre le 25 et le 30 mars pour les revenus générés en février
          </span>
        </li>
      </ol>
    </div>
  </div>
</template>
